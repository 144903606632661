import { useQuery } from '@tanstack/react-query'

import { ErrorResponse } from '../models'

import { config, getToken } from '@/api'

// @TODO
export type PipelineItem = any

const SANDBOX_QUERY_IDS = {
  LIST_ALL_PIPELINES: 'LIST_ALL_PIPELINES'
}

export const useQueryListAllPipelines = ({
  limit,
  offset
}: {
  limit: number
  offset: number
}) => {
  return useQuery<{
    total: number
    page: number
    total_pages: number
    results: PipelineItem[]
  }>({
    queryKey: [SANDBOX_QUERY_IDS.LIST_ALL_PIPELINES, limit, offset],
    queryFn: async () => {
      const token = await getToken()

      const queryParams = new URLSearchParams()

      if (limit) queryParams.append('limit', String(limit))
      if (offset) queryParams.append('offset', String(offset))

      // Build the final URL with query parameters
      const url = `${
        config.SANDBOX
      }/v1/sandbox/pipelines:all?${queryParams.toString()}`

      const res = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      if (!res.ok) {
        const error: ErrorResponse = await res.json()

        throw new Error(error.message)
      }

      const data = await res.json()

      return data
    },
    refetchOnWindowFocus: true
  })
}
